<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-29 14:38:42
 * @ Description: Component displaying the general profile services tab.
 -->

<template>
    <v-row>
        <v-col
            cols="12"
            style="position: relative;"
        >
            <!-- Add Service Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                v-if="!showAddEditServiceBoolean"
                @click="showAddService(true)"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Service</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Service Button -->

            <!-- Card View -->
            <v-row
                v-if="selectedView === 'card'"
            >
                <!-- No Services -->
                <v-col
                    v-if="profileServices === null || profileServices.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No services added
                </v-col>
                <!-- No Services -->

                <!-- Services -->
                <v-col
                    cols="12"
                    md="4"
                    lg="3"
                    v-for="(item, index) in profileServices"
                    :key="index"
                    v-else
                >
                    <v-card
                        height="398px"
                        width="100%"
                        max-width="280px"
                        class="rounded-lg pa-3 mx-auto"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                style="position: relative;"
                                class="text-center"
                            >
                                <!-- <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    aria-label="notifications"
                                    style="position: absolute; left: 4px;"
                                >
                                    <v-badge
                                        overlap
                                        content="1"
                                        color="#FFA858"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            class="ml-4"
                                        >
                                            {{ mdiBell }}
                                        </v-icon>
                                    </v-badge>
                                </v-btn> -->
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            icon
                                            class="mr-2 pr-4"
                                            aria-label="notifications"
                                            style="position: absolute; right: 0;"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon
                                                color="#2E567A"
                                                class="ml-4"
                                            >
                                                {{ mdiDotsHorizontal }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(menuItem, menuIndex) in menuItems"
                                            :key="menuIndex"
                                            @click="menuItemAction(menuItem, item)"
                                        >
                                            <v-list-item-title
                                                class="os-12-sb"
                                            >
                                                {{ menuItem }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <!-- <v-btn
                                    icon
                                    class="mr-2 pr-4"
                                    aria-label="notifications"
                                    style="position: absolute; right: 0;"
                                >
                                    <v-icon
                                        color="#2E567A"
                                    >
                                        {{ mdiDotsHorizontal }}
                                    </v-icon>
                                </v-btn> -->
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="item.image ? item.image : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                class="os-16-sb text-center py-0"
                                cols="12"
                            >
                                {{ item.name }}
                            </v-col>
                            <v-col
                                class="os-12-r text-center four-lines"
                                cols="12"
                                style="color: #8F9BB3; height: 180px; max-height: 180px; overflow-y: hidden;"
                                v-html="item.description"
                            />
                            <v-col
                                cols="12"
                                class="py-0"
                                style="position: relative;"
                            >
                                <v-divider />
                                <!-- <span
                                    class="os-12-b"
                                    style="position: absolute; top: 0; right: 12px; color: #8F9BB3; cursor: pointer;"
                                >
                                    Read more...
                                </span> -->
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-truncate"
                            >
                                <div
                                    class="os-10-r"
                                    style="color: #8F9BB3;"
                                >
                                    Industry:
                                </div>
                                <div
                                    class="os-12-sb text-truncate"
                                    style="color: #2E567A"
                                >
                                    {{ item.industry.name }}
                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                                align-self="center"
                                class="text-right mt-n1 px-2 text-truncate"
                            >
                                <div
                                    class="os-12-sb"
                                    style="color: #069B34;"
                                >
                                    Active ({{ item.active_count }})
                                </div>
                                <div
                                    class="os-12-sb"
                                    style="color: #FFA858;"
                                >
                                    Pending ({{ item.pending_count }})
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="6"
                                class="os-12-b text-truncate"
                                style="color: #2E567A;"
                                align-self="center"
                            >
                                <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                                <span v-else>Quote Upon Request</span>
                            </v-col>
                            <v-col
                                cols="6"
                                class="text-right text-truncate os-12-sb"
                                style="color: #2E567A;"
                            >
                                <v-icon
                                    color="#2E567A"
                                >
                                    {{ mdiMapMarkerOutline }}
                                </v-icon>
                                <span>{{ item.work_location.name }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Services -->
            </v-row>
            <!-- Card View -->

            <!-- List View -->
            <v-row
                v-if="selectedView === 'list'"
            >
                <!-- Search -->
                <v-col
                    cols="12"
                    class="pb-0 pt-10"
                >
                    <div
                        class="os-17-sb px-3"
                        style="background-color: white; height: 60px; display: flex; align-items: center;"
                    >
                        All Services
                        <v-spacer />
                        <div
                            style="width: 275px;"
                        >
                            <v-text-field
                                dense
                                hide-details
                                outlined
                                color="#2E567A"
                                placeholder="Search..."
                                class="os-13-r"
                                background-color="white"
                                style="width: 275px;"
                            >
                                <template v-slot:append>
                                    <v-icon
                                        color="#C5CEE0"
                                        class="ma-0"
                                    >
                                        {{ mdiMagnify }}
                                    </v-icon>
                                </template>
                            </v-text-field>
                        </div>
                    </div>
                </v-col>
                <!-- Search -->

                <!-- Services -->
                <v-col
                    cols="12"
                    class="pt-0"
                >
                    <v-data-table
                        :headers="serviceTableHeaders"
                        :items="profileServices"
                        item-key="id"
                        hide-default-footer
                    >
                        <template v-slot:[`item.rate`]="{ item }">
                            <span v-if="item.interval.id < 4">{{ item.currency.symbol }}{{ item.rate }} per {{ item.interval.name }}</span>
                            <span v-else>Quote Upon Request</span>
                        </template>
                        <template v-slot:[`item.industry`]="{ item }">
                            {{ item.industry.name }}
                        </template>
                        <template v-slot:[`item.location`]="{ item }">
                            {{ item.work_location.name }}
                        </template>
                        <template v-slot:[`item.requests`]="{ item }">
                            <span
                                class="os-12-sb"
                                style="color: #069B34;"
                            >
                                Active ({{ item.active_count }})
                            </span>
                            &nbsp;
                            <span
                                class="os-12-sb"
                                style="color: #FFA858;"
                            >
                                Pending ({{ item.pending_count }})
                            </span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn
                                icon
                                @click="menuItemAction ('Edit', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiArrowRightThinCircleOutline }}
                                </v-icon>
                            </v-btn>
                            <!-- <v-btn
                                icon
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiPrinterOutline }}
                                </v-icon>
                            </v-btn> -->
                            <!-- <v-btn
                                icon
                                @click="menuItemAction ('Delete', item)"
                            >
                                <v-icon
                                    color="#2E567A"
                                    style="opacity: 0.65;"
                                    size="20"
                                >
                                    {{ mdiTrashCanOutline }}
                                </v-icon>
                            </v-btn> -->
                        </template>
                    </v-data-table>
                </v-col>
                <!-- Services -->
            </v-row>
            <!-- List View -->

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditServiceBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> a Service
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <v-col
                            cols="12"
                        >
                            <v-form
                                v-model="validServiceForm"
                                ref="serviceForm"
                            >
                                <v-row>
                                    <!-- Services Form -->
                                    <v-col
                                        cols="7"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                class="pb-0 os-15-sb"
                                            >
                                                Service Name<span style="color: red;">*</span>
                                                <br />
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    placeholder="Service Name (E.g. Accountant, Yoga Instructor, Gardener)"
                                                    :rules="shortNameRules"
                                                    counter="30"
                                                    v-model="serviceToUpsert.name"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="py-0 os-15-sb"
                                            >
                                                Industry<span style="color: red;">*</span>
                                                <br />
                                                <v-select
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    :items="industries"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    v-model="serviceToUpsert.industry"
                                                    :rules="selectRules"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="py-0 os-15-sb"
                                            >
                                                Service Description<span style="color: red;">*</span>
                                                <br />
                                                <general-tip-tap-text-editor-component
                                                    class="pb-6"
                                                    @textChanged="descriptionChanged"
                                                    :text="serviceToUpsert.description"
                                                />
                                                <div
                                                    v-if="descriptionTooLong"
                                                    style="color: red;"
                                                    class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                                >
                                                    Max 180 characters
                                                </div>
                                                <div
                                                    v-else-if="!validDescription"
                                                    style="color: red;"
                                                    class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                                >
                                                    Required field
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        class="os-13-r text-center pt-6"
                                        cols="2"
                                    >
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="avatar"
                                            accept="image/*"
                                            @change="avatarSelected"
                                        >
                                        <v-avatar
                                            size="100"
                                            class="mb-2"
                                        >
                                            <v-img
                                                :src="avatar ? avatar : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                                contain
                                            />
                                        </v-avatar>
                                        <br />
                                        <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
                                    </v-col>

                                    <!-- Location -->
                                    <v-col
                                        cols="12"
                                        class="os-22-sb"
                                    >
                                        Service Location
                                    </v-col>
                                    <v-col
                                        cols="7"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="7"
                                        class="os-15-sb pb-0"
                                    >
                                        Please provide location information for service provided<span style="color: red;">*</span>
                                    </v-col>
                                    <v-col
                                        cols="7"
                                        class="py-0"
                                    >
                                        <v-radio-group
                                            v-model="serviceToUpsert.work_location.id"
                                            hide-details
                                        >
                                            <v-radio
                                                v-for="(item, index) in serviceLocations"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.id"
                                                color="#2E567A"
                                                class="radio-text"
                                                :ripple="false"
                                            />
                                        </v-radio-group>
                                    </v-col>
                                    
                                    <v-col
                                        cols="7"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                        class="pb-0"
                                    >
                                        <div class="os-15-sb">Addresses:</div>
                                    </v-col>

                                    <v-col
                                        cols="7"
                                        v-if="serviceToUpsert.work_location.id !== 2 && addresses.length > 0"
                                    >
                                        <v-row
                                            v-for="(item, index) in addresses"
                                            :key="index"
                                            justify="center"
                                            class="mx-3 my-1"
                                        >
                                            <v-col
                                                class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                                align-self="center"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                    class="mb-1"
                                                >
                                                    {{ mdiMapMarkerOutline }}
                                                </v-icon>
                                                <span>{{ item.address_line_1 }}</span>
                                                <!-- <span>, {{ item.city }}</span>
                                                <span>, {{ item.state }}</span>
                                                <span>, {{ item.country.name }}</span> -->
                                            </v-col>
                                            <v-col
                                                class="flex-grow-0 flex-shrink-1 pa-0"
                                                v-if="addresses.length > 1"
                                            >
                                                <v-btn
                                                    icon
                                                    @click="removeAddress(item)"
                                                >
                                                    <v-icon
                                                        color="black"
                                                        size="20"
                                                    >
                                                        {{ mdiClose }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        cols="7"
                                        v-else-if="serviceToUpsert.work_location.id !== 2 && addresses.length === 0"
                                        class="os-12-r text-center"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No addresses added
                                    </v-col>

                                    <!-- Google Autocomplete -->
                                    <v-col
                                        cols="7"
                                        class="py-0 os-15-sb"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                    >
                                        Add Address<span style="color: red;">*</span>
                                        <br />
                                        <v-row>
                                            <v-col
                                                class="flex-shrink-0 flex-grow-1"
                                            >
                                                <general-google-autocomplete-address-component
                                                    @googlePlaceSelected="googlePlaceSelected"
                                                    @addressChanged="addressChanged"
                                                    :placeholder="'Type the address to start a Google Maps lookup'"
                                                    :address="address"
                                                    :clearable="clearableAddress"
                                                />
                                            </v-col>
                                            <!-- <v-col
                                                class="flex-shrink-1 flex-grow-0"
                                            >
                                                <v-btn
                                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                                    color="#FFA858"
                                                    style="margin-top: 6px;"
                                                    @click="addAddress"
                                                    :disabled="googlePlaceAltered || !address.google_places_id"
                                                >
                                                    Add
                                                    <v-icon
                                                        class="ml-1"
                                                    >
                                                        {{ mdiPlusCircleOutline }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-col> -->
                                        </v-row>
                                    </v-col>
                                    <!-- Google Autocomplete -->

                                    <!-- Map -->
                                    <v-col
                                        cols="7"
                                        class="pt-0"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                    >
                                        <general-map-component
                                            :mapMarkers="mapMarkers"
                                            :avatar="$store.state.user.userData.avatar"
                                        />
                                    </v-col>
                                    <!-- Map -->
                                    <!-- Location -->

                                    <v-col
                                        cols="12"
                                        class="os-22-sb"
                                    >
                                        Rate
                                    </v-col>
                                    <v-col
                                        cols="7"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="7"
                                    >
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                class="pb-0"
                                            >
                                                <v-checkbox
                                                    label="Quote Upon Request"
                                                    color="#2E567A"
                                                    v-model="quoteUponRequest"
                                                    dense
                                                    hide-details
                                                    class="test mx-auto"
                                                    style="margin-top: 0px; padding-top: 0px;"
                                                    @change="toggleQuoteUponRequest"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="4"
                                                class="pb-0 os-15-sb"
                                            >
                                                Currency<span style="color: red;">*</span>
                                                <br />
                                                <v-select
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    :rules="!quoteUponRequest ? selectRules : []"
                                                    :items="currencies"
                                                    return-object
                                                    item-text="name"
                                                    item-value="id"
                                                    v-model="serviceToUpsert.currency"
                                                    :readonly="currencies.length === 1 && serviceToUpsert.currency.id !== null"
                                                    :disabled="quoteUponRequest"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="4"
                                                class="pb-0 os-15-sb"
                                            >
                                                Rate<span style="color: red;">*</span>
                                                <br />
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="serviceToUpsert.rate"
                                                    :prefix="serviceToUpsert.currency.symbol"
                                                    type="number"
                                                    :rules="!quoteUponRequest ? rateRules : []"
                                                    :disabled="quoteUponRequest"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="4"
                                                class="pb-0 os-15-sb"
                                            >
                                                Unit / Per<span style="color: red;">*</span>
                                                <br />
                                                <v-select
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    :rules="!quoteUponRequest ? selectRules : []"
                                                    :items="paymentIntervals"
                                                    return-object
                                                    item-text="name"
                                                    item-value="id"
                                                    v-model="serviceToUpsert.interval"
                                                    :disabled="quoteUponRequest"
                                                    @change="intervalChanged"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!-- Add/Cancel Buttons -->
                                    <v-col
                                        align-self="end"
                                        class="pb-7"
                                        cols="5"
                                    >
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg os-13-sb"
                                            color="#FFA858"
                                            :disabled="cannotSaveService"
                                            @click="upsertService"
                                        >
                                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> Service
                                            <v-icon
                                                class="ml-1"
                                                v-if="action === 'add'"
                                            >
                                                {{ mdiPlusCircleOutline }}
                                            </v-icon>
                                            <v-icon
                                                class="ml-1"
                                                v-else-if="action === 'edit'"
                                            >
                                                {{ mdiCircleEditOutline }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            text
                                            class="no-uppercase rounded-lg os-13-sb"
                                            color="#8F9BB3"
                                            @click="showAddService(false)"
                                        >
                                            Cancel
                                        </v-btn>
                                    </v-col>
                                    <!-- Add/Cancel Buttons -->
                                </v-row>
                            </v-form>
                        <!-- Services Form -->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditServiceBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> a Service
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <v-col
                            cols="12"
                        >
                            <v-form
                                v-model="validServiceForm"
                                ref="serviceForm"
                            >
                                <v-row>
                                    <!-- Services Form -->
                                    <v-col
                                        class="os-13-r text-center pt-6"
                                        cols="12"
                                    >
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="avatar"
                                            accept="image/*"
                                            @change="avatarSelected"
                                        >
                                        <v-avatar
                                            size="100"
                                            class="mb-2"
                                        >
                                            <v-img
                                                :src="avatar ? avatar : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                                contain
                                                class="mb-2"
                                            />
                                        </v-avatar>
                                        <br />
                                        <span @click="selectAvatar" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                        align-self="end"
                                    >
                                        Service Name<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            placeholder="Service Name (E.g. Accountant, Yoga Instructor, Gardener)"
                                            :rules="shortNameRules"
                                            counter="30"
                                            v-model="serviceToUpsert.name"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        Industry<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :items="industries"
                                            item-text="name"
                                            item-value="id"
                                            return-object
                                            v-model="serviceToUpsert.industry"
                                            :rules="selectRules"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        Service Description<span style="color: red;">*</span>
                                        <br />
                                        <general-tip-tap-text-editor-component
                                            class="pb-6"
                                            @textChanged="descriptionChanged"
                                            :text="serviceToUpsert.description"
                                        />
                                        <div
                                            v-if="descriptionTooLong"
                                            style="color: red;"
                                            class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                        >
                                            Max 180 characters
                                        </div>
                                        <div
                                            v-else-if="!validDescription"
                                            style="color: red;"
                                            class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                        >
                                            Required field
                                        </div>
                                    </v-col>

                                    <!-- Location -->
                                    <v-col
                                        cols="12"
                                        class="os-22-sb"
                                    >
                                        Service Location
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="os-15-sb pb-0"
                                    >
                                        Please provide location information for service provided<span style="color: red;">*</span>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0 px-2"
                                    >
                                        <v-radio-group
                                            v-model="serviceToUpsert.work_location.id"
                                        >
                                            <v-radio
                                                v-for="(item, index) in serviceLocations"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.id"
                                                color="#2E567A"
                                                class="radio-text"
                                                :ripple="false"
                                            />
                                        </v-radio-group>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        v-if="serviceToUpsert.work_location.id !== 2 && addresses.length > 0"
                                    >
                                        <div class="os-15-sb">Addresses:</div>
                                        <v-row
                                            v-for="(item, index) in addresses"
                                            :key="index"
                                            justify="center"
                                            class="mx-3 mt-3"
                                        >
                                            <v-col
                                                class="flex-grow-1 flex-shrink-0 text-truncate pa-0"
                                                align-self="center"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                    size="20"
                                                    class="mb-1"
                                                >
                                                    {{ mdiMapMarkerOutline }}
                                                </v-icon>
                                                <span>{{ item.address_line_1 }}</span>
                                                <!-- <span>, {{ item.city }}</span>
                                                <span>, {{ item.state }}</span>
                                                <span>, {{ item.country.name }}</span> -->
                                            </v-col>
                                            <v-col
                                                class="flex-grow-0 flex-shrink-1 pa-0"
                                                v-if="addresses.length > 1"
                                            >
                                                <v-btn
                                                    icon
                                                    @click="removeAddress(item)"
                                                >
                                                    <v-icon
                                                        color="black"
                                                        size="20"
                                                    >
                                                        {{ mdiClose }}
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        v-else-if="serviceToUpsert.work_location.id !== 2 && addresses.length === 0"
                                        class="os-12-r text-center"
                                        style="color: rgba(0, 0, 0, 0.38);"
                                    >
                                        No locations added
                                    </v-col>

                                    <!-- Google Autocomplete -->
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                    >
                                        Add Address<span style="color: red;">*</span>
                                        <br />
                                        <general-google-autocomplete-address-component
                                            @googlePlaceSelected="googlePlaceSelected"
                                            @addressChanged="addressChanged"
                                            :placeholder="'Type the address to start a Google Maps lookup'"
                                            :address="address"
                                            :clearable="clearableAddress"
                                        />
                                    </v-col>
                                    <!-- <v-col
                                        cols="12"
                                        class="text-right pt-0"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                    >
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg os-13-sb"
                                            color="#FFA858"
                                            style="margin-top: 6px;"
                                            @click="addAddress"
                                            :disabled="googlePlaceAltered || !address.google_places_id"
                                        >
                                            Add
                                            <v-icon
                                                class="ml-1"
                                            >
                                                {{ mdiPlusCircleOutline }}
                                            </v-icon>
                                        </v-btn>
                                    </v-col> -->
                                    <!-- Google Autocomplete -->

                                    <!-- Map -->
                                    <v-col
                                        cols="12"
                                        class="pt-0"
                                        v-if="serviceToUpsert.work_location.id !== 2"
                                    >
                                        <general-map-component
                                            :mapMarkers="mapMarkers"
                                            :avatar="$store.state.user.userData.avatar"
                                        />
                                    </v-col>
                                    <!-- Map -->
                                    <!-- Location -->

                                    <v-col
                                        cols="12"
                                        class="os-22-sb"
                                    >
                                        Rate
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pb-0"
                                    >
                                        <v-checkbox
                                            label="Quote Upon Request"
                                            color="#2E567A"
                                            v-model="quoteUponRequest"
                                            dense
                                            hide-details
                                            class="test mx-auto"
                                            style="margin-top: 0px; padding-top: 0px;"
                                            @change="toggleQuoteUponRequest"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="pb-0 os-15-sb"
                                    >
                                        Currency<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :rules="selectRules"
                                            :items="currencies"
                                            return-object
                                            item-text="name"
                                            item-value="id"
                                            v-model="serviceToUpsert.currency"
                                            :readonly="currencies.length === 1 && serviceToUpsert.currency.id !== null"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="pb-0 os-15-sb"
                                    >
                                        Rate<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="serviceToUpsert.rate"
                                            :prefix="serviceToUpsert.currency.symbol"
                                            type="number"
                                            :rules="rateRules"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="6"
                                        class="os-15-sb"
                                    >
                                        Unit / Per<span style="color: red;">*</span>
                                        <br />
                                        <v-select
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            :rules="selectRules"
                                            :items="paymentIntervals"
                                            return-object
                                            item-text="name"
                                            item-value="id"
                                            v-model="serviceToUpsert.interval"
                                        />
                                    </v-col>
                                    <!-- Services Form -->

                                    <!-- Add/Cancel Buttons -->
                                    <v-col
                                        cols="6"
                                        :class="$store.state.general.customBreakpoints.xxs ? 'px-0' : 'px-2'"
                                        align-self="center"
                                        class="text-right"
                                    >
                                        <v-btn
                                            class="white--text no-uppercase rounded-lg os-13-sb"
                                            color="#FFA858"
                                            :disabled="cannotSaveService"
                                            @click="upsertService"
                                        >
                                            <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                            <v-icon
                                                class="ml-1"
                                                v-if="action === 'add'"
                                            >
                                                {{ mdiPlusCircleOutline }}
                                            </v-icon>
                                            <v-icon
                                                class="ml-1"
                                                v-else-if="action === 'edit'"
                                            >
                                                {{ mdiCircleEditOutline }}
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            text
                                            class="no-uppercase rounded-lg os-13-sb"
                                            color="#8F9BB3"
                                            @click="showAddService(false)"
                                        >
                                            Cancel
                                        </v-btn>
                                    </v-col>
                                    <!-- Add/Cancel Buttons -->
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Service'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import { mdiClose, mdiPlusCircleOutline, mdiBell, mdiDotsHorizontal, mdiMapMarkerOutline, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiMagnify, mdiCircleEditOutline } from '@mdi/js'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralMapComponent from '@/components/general/map/GeneralMapComponent'
    import GeneralGoogleAutocompleteAddressComponent from '@/components/general/google/GeneralGoogleAutocompleteAddressComponent.vue'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import { LookupIndustryController, LookupLocationController, LookupPaymentController, LookupPreferenceController, GeneralProfileServiceController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'ProfileServicesTabComponent.vue',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralMapComponent,
            GeneralGoogleAutocompleteAddressComponent,
            GeneralConfirmDeleteOverlayComponent
        },

        props: {
            selectedView: {
                type: String,
                required: true
            }
        },

        watch: {
            showAddEditServiceBoolean: {
                handler (value) {
                    if (value) {
                        window.scrollTo(0, 0)

                        if (this.currencies.length === 1) this.serviceToUpsert.currency = this.currencies[0]
                    }
                }
            }
        },

        computed: {
            shortNameRules () {
                if (this.serviceToUpsert.name) {
                    return [
                        v => !!v || 'Required field',
                        v => v.replace(/\s/g, '') !== '' || 'Required field',
                        v => v.length <= 30 || 'Max 30 characters'
                    ]
                } else return [ 'Required field' ]
            },

            selectRules () {
                return [
                    v => (!!v && !!v.id) || 'Required Field'
                ]
            },

            rateRules () {
                return [
                    v => !!v || 'Required field',
                    v => v > 0 || 'Amount > 0'
                ]
            },

            cannotSaveService () {
                if (!this.serviceToUpsert.description || this.descriptionTooLong) return true
                else if (this.serviceToUpsert.work_location.id !== 2 && this.addresses.length === 0) return true
                else return !this.validServiceForm
            }
        },

        data () {
            return {
                mdiClose,
                mdiPlusCircleOutline,
                mdiBell,
                mdiDotsHorizontal,
                mdiMapMarkerOutline,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                mdiCircleEditOutline,
                showAddEditServiceBoolean: false,
                serviceTableHeaders: [
                    {
                        text: 'Service',
                        value: 'name'
                    },
                    {
                        text: 'Industry',
                        value: 'industry'
                    },
                    {
                        text: 'Location',
                        value: 'location'
                    },
                    {
                        text: 'Rate',
                        value: 'rate'
                    },
                    {
                        text: 'Service Requests',
                        value: 'requests'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                profileServices: [],
                serviceLocations: [
                    {
                        id: 1,
                        text: 'Virtual Service (Provided remotely)'
                    },
                    {
                        id: 2,
                        text: 'Provided at a specific location (i.e. Address, Gym, Office)'
                    },
                    {
                        id: 3,
                        text: 'Provided remotely and at a specific location'
                    }
                ],
                menuItems: [
                    'Edit',
                    // 'Print',
                    // 'Delete'
                ],
                mapMarkers: [],
                addresses: [],
                address: {
                    user_id: null,
                    address_line_1: null,
                    google_places_id: null,
                    lat: null,
                    lng: null,
                    state: null,
                    city: null,
                    country: {},
                    timezone: null
                },
                validServiceForm: false,
                serviceToUpsert: {
                    description: null,
                    currency: {},
                    work_location: {
                        id: 1
                    },
                    is_visible: true,
                    job_title: {
                        id: 1
                    }
                },
                industries: [],
                currencies: [],
                paymentIntervals: [],
                lookupIndustryController: null,
                lookupLocationController: null,
                lookupPaymentController: null,
                lookupPreferenceController: null,
                generalProfileServiceController: null,
                validDescription: true,
                serviceFormData: null,
                googlePlaceAltered: false,
                avatar: null,
                avatarFile: null,
                action: 'add',
                serviceToDelete: null,
                showDeleteConfirmationOverlayBoolean: false,
                clearableAddress: false,
                countries: [],
                descriptionTooLong: false,
                quoteUponRequest: false
            }
        },

        beforeMount () {
            this.lookupIndustryController = new LookupIndustryController()
            this.lookupLocationController = new LookupLocationController()
            this.lookupPaymentController = new LookupPaymentController()
            this.lookupPreferenceController = new LookupPreferenceController()
            this.generalProfileServiceController = new GeneralProfileServiceController()
        },

        async mounted () {
            this.getIndustries()
            this.getCurrencies()
            this.getPaymentIntervals()
            this.getWorkLocations()
            this.getCountries()
            await this.getProfileServices()
        },

        methods: {
            async getIndustries () {
                try {
                    this.industries = await this.lookupIndustryController.get(true, false, false, false, false, false)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCurrencies () {
                try {
                    this.currencies = await this.lookupLocationController.getCurrencies()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getPaymentIntervals () {
                try {
                    this.paymentIntervals = await this.lookupPaymentController.getPaymentIntervals()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getWorkLocations () {
                try {
                    this.serviceLocations = await this.lookupPreferenceController.getWorkLocations()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getCountries () {
                try {
                    this.countries = await this.lookupLocationController.getCountries()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileServices () {
                try {
                    this.profileServices = await this.generalProfileServiceController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            addressChanged (address, googlePlaceAltered) {
                this.clearableAddress = false
                this.googlePlaceAltered = googlePlaceAltered
                this.address.address_line_1 = address
                this.autocompleteLocationValidate()
            },

            autocompleteLocationValidate () {
                if (this.address.address_line_1 === '' || (this.address.address_line_1.replace(/\s/g, '') === '')) {
                    this.address = {
                        user_id: this.address.user_id,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }
                }
            },

            toggleQuoteUponRequest () {
                // this.$nextTick(() => {
                if (this.quoteUponRequest) {
                    this.serviceToUpsert.currency.id = 110
                    this.$nextTick(() => {
                        this.serviceToUpsert.rate = '0'
                    })
                    this.serviceToUpsert.interval = {
                        id: 4
                    }
                } else {
                    this.serviceToUpsert.interval = {
                        id: null
                    }
                    this.$refs.serviceForm.validate()
                }
                // })
            },

            intervalChanged () {
                if (this.serviceToUpsert.interval.id === 4) {
                    this.quoteUponRequest = true
                    this.toggleQuoteUponRequest()
                } else this.quoteUponRequest = false
            },

            async upsertService () {
                try {
                    this.serviceFormData = new FormData()
                    let serviceFormObject = {}
                    Object.assign(serviceFormObject, this.serviceToUpsert)

                    if (this.avatarFile) this.serviceFormData.append('image', this.avatarFile)
                    if (serviceFormObject.work_location.id !== 2 && this.addresses.length > 0) this.serviceFormData.append('geo_location', JSON.stringify(this.addresses))
                    if (serviceFormObject.is_visible === 1) serviceFormObject.is_visible = true
                    else if (serviceFormObject.is_visible === 0) serviceFormObject.is_visible = false

                    for (let key in serviceFormObject) {
                        if (serviceFormObject[key]) {
                            if (serviceFormObject[key].id) serviceFormObject[key] = JSON.stringify(serviceFormObject[key])
                            if (key !== 'image' && key !== 'geo_location') this.serviceFormData.append(key, serviceFormObject[key])
                        }
                    }

                    const response = await this.generalProfileServiceController.upsert(this.serviceFormData)

                    if (this.action === 'edit') {
                        const serviceIndex = this.profileServices.findIndex(serviceToFind => serviceToFind.id === response[0].id)
                        if (serviceIndex > -1) this.profileServices[serviceIndex] = response[0]

                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    } else this.profileServices = response

                    this.showAddEditServiceBoolean = false
                    this.avatarFile = null
                    this.addresses = []
                    this.mapMarkers = []
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }

                this.serviceFormData = null
            },

            selectAvatar () {
                this.$refs.avatar.click()
            },

            avatarSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = async function () {
                        self.avatar = reader.result
                        self.avatarFile = files[0]
                    }

                    reader.readAsDataURL(f)
                }
            },

            descriptionChanged (text) {
                this.serviceToUpsert.description = text

                var div = document.createElement('div')
                div.innerHTML = text
                var cleanText = div.textContent || div.innerText || ''

                if (cleanText.length > 180) this.descriptionTooLong = true
                else this.descriptionTooLong = false

                if (this.serviceToUpsert.description === '<p></p>') {
                    this.serviceToUpsert.description = null
                    this.validDescription = false
                } else if (this.descriptionTooLong) this.validDescription = false
                else this.validDescription = true
            },

            showAddService (value) {
                this.showAddEditServiceBoolean = value
                this.action = 'add'
                this.quoteUponRequest = false

                this.serviceToUpsert = {
                    description: null,
                    currency: {},
                    work_location: {
                        id: 1
                    },
                    is_visible: true,
                    job_title: {
                        id: 1
                    }
                }
                this.avatar = null
                this.addresses = []
                this.mapMarkers = []
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.serviceToUpsert, item)
                    this.action = 'edit'
                    this.avatar = item.image
                    if (item.geo_location && item.geo_location.length > 0) {
                        this.addresses = item.geo_location
                        for (let index = 0; index < item.geo_location.length; index++) {
                            const address = item.geo_location[index];
                            const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === this.address.lat && mapMarkerToFind.lng === this.address.lng)
                            if (mapMarkerIndex < 0) this.mapMarkers.push({ lat: address.lat, lng: address.lng })
                        }
                    }

                    if (item.interval.id === 4) this.quoteUponRequest = true
                    else this.quoteUponRequest = false

                    this.showAddEditServiceBoolean = true
                } // else if (action === 'Delete') this.confirmDeleteService(item)
            },

            async deleteService (service) {
                try {
                    // this.profileIndustryJobTitles = await this.generalProfileCategoryController.delete(industry)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.serviceToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmDeleteService (service) {
                this.serviceToDelete = service
                this.showDeleteConfirmationOverlay(true)
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteService(this.serviceToDelete)
            },

            googlePlaceSelected (place) {
                this.googlePlaceAltered = false
                this.address = place

                const countryIndex = this.countries.findIndex(country => country.iso_2 === this.address.country.country_code)
                if (countryIndex > -1) this.address.country.id = this.countries[countryIndex].id
                // Object.assign(this.address, place)

                this.$nextTick(() => {
                    this.addAddress()
                })
            },

            addAddress () {
                if (this.serviceToUpsert.work_location.id !== 2 && this.address) {
                    const addressIndex = this.addresses.findIndex(addressToFind => addressToFind.google_places_id === this.address.google_places_id)
                    if (addressIndex < 0) this.addresses.push(this.address)

                    const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === this.address.lat && mapMarkerToFind.lng === this.address.lng)
                    if (mapMarkerIndex < 0) this.mapMarkers.push({ lat: this.address.lat, lng: this.address.lng })

                    this.address = {
                        user_id: null,
                        address_line_1: null,
                        google_places_id: null,
                        lat: null,
                        lng: null,
                        state: null,
                        city: null,
                        country: {},
                        timezone: null
                    }

                    this.clearableAddress = true
                }
            },

            async removeAddress (item) {
                try {
                    if (item.geo_id) {
                        await this.generalProfileServiceController.deleteLocation(item)
                    }

                    const addressIndex = this.addresses.findIndex(addressToFind => addressToFind === item)
                    if (addressIndex > -1) this.addresses.splice(addressIndex, 1)

                    const mapMarkerIndex = this.mapMarkers.findIndex(mapMarkerToFind => mapMarkerToFind.lat === item.lat && mapMarkerToFind.lng === item.lng)
                    if (mapMarkerIndex > -1) this.mapMarkers.splice(mapMarkerIndex, 1)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            }
        }
    }
</script>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }

    .radio-text /deep/ label {
        color: black !important;
        font-size: 13px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }

    .test /deep/ label {
        color: black !important;
        font-size: 13px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
    }

    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }
</style>
